import { NgModule } from '@angular/core';
import { Routes, RouterModule, ExtraOptions } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { AboutComponent } from './about/about.component';
import { TestimonialsComponent } from './testimonials/testimonials.component';
import { MybookingsComponent } from './mybookings/mybookings.component';
import { TermsComponent } from './terms/terms.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { MyprofileComponent } from './myprofile/myprofile.component';
import { BookingComponent } from './booking/booking.component';
import { PaymentComponent } from './payment/payment.component';
import { DetailedBookingDetailsComponent } from './detailed-booking-details/detailed-booking-details.component';
import { InvoiceComponent } from './invoice/invoice.component';
import { FavouritesComponent } from './favourites/favourites.component';


const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
  },
  {
    path: 'about',
    component: AboutComponent,
  },
  {
    path: 'testimonials',
    component: TestimonialsComponent,
  },
  {
    path: 'my-bookings',
    component: MybookingsComponent,
  },
  {
    path: 'terms-conditions',
    component: TermsComponent,
  },
  {
    path: 'privacy',
    component: PrivacyComponent,
  },
  {
    path: 'my-profile',
    component: MyprofileComponent,
  },
  {
    path: 'booking',
    component: BookingComponent,
  },
  {
    path: 'payment',
    component: PaymentComponent,
  },
  {
    path: 'booking-detailed-view',
    component: DetailedBookingDetailsComponent,
  },
  {
    path: 'invoice',
    component:InvoiceComponent,
  },
  {
    path: 'favourites',
    component:FavouritesComponent,
  },
];
const routerOptions: ExtraOptions = {
  useHash: false,
  anchorScrolling: 'enabled',
  scrollPositionRestoration: 'enabled',
  onSameUrlNavigation: 'ignore',
  urlUpdateStrategy: 'eager',
};

@NgModule({
  imports: [RouterModule.forRoot(routes, routerOptions)],
  exports: [RouterModule],
})
export class AppRoutingModule {
  AboutComponent: any;
  TestimonialsComponent: any;
  MybookingsComponent: any;
  TermsComponent: any;
  PrivacyComponent: any;
  MyprofileComponent: any;
  BookingComponent:any;
  PaymentComponent:any;
  DetailedBookingDetailsComponent:any;
  InvoiceComponent:any;
  FavouritesComponent:any;
}
