<section class="detailed-booking pt-5 pb-5">
  <div *ngFor="let e of li;">
    <div class="container" *ngIf="e.t_id == pageid">
      <div class="row">
        <div class="col-12">
          <h2>
            {{e.t_trip_fromlocation}} <span>To</span> {{e.t_trip_tolocation}}
          </h2>
        </div>
      </div>
      <div class="row">
        <div class="col-md-8">
            
    <div class="white-box">
          <div class="row">
            <div class="col-12">

              <div class="bstatus d-flex">
                <span class="cnttct">Trip Status</span>&nbsp;
                <div>
                  <span class="statusstyle">
                    <div *ngIf="e.t_trip_status == 'OnGoing';">
                      <span class="badge badge-pill badge-warning">Ongoing Trip</span>
                      
                     </div>
                      <div *ngIf="e.t_trip_status == 'completed';">
                        <span  class="badge badge-pill badge-success">
                          Completed Trip
                        </span>
                     </div>
                     <div *ngIf="e.t_trip_status == 'yettostart';">
                      <span class="badge badge-pill badge-primary">Yet to Start</span>
                       
                      </div>
                      <div *ngIf="e.t_trip_status == 'cancelled';">
                        <span class="badge badge-pill badge-danger">Cancelled Trip</span>
                       
                      </div>
                      <div *ngIf="e.t_trip_status == 'yettoconfirm';">
                        <span class="badge badge-pill badge-info">Yet to Confirm</span>
                       
                      </div>
                  </span>
                 </div>
                
              </div>
            </div>
            <div class="col-12">
              <hr>
              <div class="d-flex thumb">
                <div class="box">
                  <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 29.046 29.046"
                    style="enable-background:new 0 0 29.046 29.046;" xml:space="preserve">
                    <g>
                      <path d="M25.334,17.932c0,1.291-1.193,2.104-2.486,2.104h-0.01c0.965,0.166,2.111,1.331,2.111,2.462
                c0,1.243-1.184,2.019-2.43,2.019h-1.072c0.844,0.243,1.977,1.375,1.977,2.462c0,1.27-1.191,2.067-2.459,2.067H10.156
                c-3.56,0-6.443-2.887-6.443-6.447c0,0,0-6.872,0-6.88c0-2.522,1.395-5.189,3.59-6.042c1.711-1.126,5.15-3.133,5.883-6.85
                c0-1.449,0-2.809,0-2.809s4.807-0.52,4.807,3.999c0,5.322-3.348,6.186-0.686,6.314h3.98c1.406,0,2.621,1.37,2.621,2.779
                c0,1.217-1.154,2.006-2.119,2.254h1.059C24.141,15.365,25.334,16.642,25.334,17.932z" />
                      <g>
                      </g>
                      <g>
                      </g>
                      <g>
                      </g>
                      <g>
                      </g>
                      <g>
                      </g>
                      <g>
                      </g>
                      <g>
                      </g>
                      <g>
                      </g>
                      <g>
                      </g>
                      <g>
                      </g>
                      <g>
                      </g>
                      <g>
                      </g>
                      <g>
                      </g>
                      <g>
                      </g>
                      <g>
                      </g>
                    </g>
                    <g>
                    </g>
                    <g>
                    </g>
                    <g>
                    </g>
                    <g>
                    </g>
                    <g>
                    </g>
                    <g>
                    </g>
                    <g>
                    </g>
                    <g>
                    </g>
                    <g>
                    </g>
                    <g>
                    </g>
                    <g>
                    </g>
                    <g>
                    </g>
                    <g>
                    </g>
                    <g>
                    </g>
                    <g>
                    </g>
                  </svg>

                </div>
                <div class="box">
                  <p>Thank you for booking your ride with Yoder Toter</p>
                </div>
              </div>
              <hr>
            </div>

          </div>
          <div class="row">
            <div class="col-12">
              <h2>Booking Details</h2>
              <table class="table table-striped">
                <tr>
                  <td class="question">
                    Trip Id:
                  </td>

                  <td>
                    {{e.t_in_id}}
                  </td>
                </tr>
                <tr>
                  <td class="question">
                    From:
                  </td>

                  <td>
                    {{e.t_trip_fromlocation}}
                  </td>
                </tr>
                <tr>
                  <td class="question">
                    Intermediate Stops:
                  </td>

                  <td>
                    {{checkstops(e.t_intermediate_stops)}}
                    <input class="d-none" id="temp" value="{{e.t_intermediate_stops }}">
                  </td>
                </tr>
                <tr>
                  <td class="question">
                    To:
                  </td>
                  <td>
                    {{e.t_trip_tolocation}}
                  </td>
                </tr>
                <tr>
                  <td class="question">
                    Booked at:
                  </td>
                  <td>
                    {{e.t_created_date | date:'MM/dd/yyyy'}}
                  </td>
                </tr>
                <tr>
                  <td class="question">
                    Booking Type:
                  </td>
                  <td>
                    {{e.t_booking_type}}

                  </td>
                </tr>
                
                <tr>
                  <td class="question">
                    Starting Date:
                  </td>
                  <td>
                    {{e.t_start_date|  date:'MM/dd/yyyy'}}
                  </td>
                </tr>
                <tr>
                  <td class="question">
                    Preffered Driver:
                  </td>
                  <td>
                   {{ e.t_prefer_driver.split("/").pop()}}

                  </td>
                </tr>
                <tr>
                  <td class="question">
                    Trip Type:
                  </td>
                  <td *ngIf="e.t_type=='outstation'">
                    Long Distance
                  </td>
                  <td *ngIf="e.t_type!='outstation'">
                    {{e.t_type}}
                  </td>
                </tr>
                <tr  *ngIf="e.t_type=='outstation'">
                  <td class="question">
                    Return Date:
                  </td>
                  <td>
                    {{e.t_end_date| date:'MM/dd/yyyy'}}

                  </td>
                </tr>
                <tr>
                  <td class="question">
                    Comment:
                  </td>
                  <td>
                    {{e.t_comment}}
                  </td>
                </tr>
                <tr>
                  <td class="question">
                    Vehicle:
                  </td>
                  <td>
                    {{e.t_vechicle}}
                  </td>
                </tr>
                <tr>
                  <td class="question">
                    Additional Charge:
                  </td>
                  <td>
                    ${{e.t_additional_charge}}
                  </td>
                </tr>
                <tr>
                  <td class="question">
                    Total Charge:
                  </td>
                  <td>
                    ${{addtotal(e.t_additional_charge,e.t_trip_amount)}}
                  </td>
                </tr>
                
              </table>
            </div>
          </div>
          </div>
         
        </div>
        <div class="col-md-4">
            <div class="white-box">
                <h2>
                    Need Yoder Toter Help?
                </h2>
                <p>
                    We would be happy to help you !
                </p>
                <div class="call-box">
                    <mat-icon>phone</mat-icon>
                    <h3>330-474-9507</h3>
                </div>
            </div>
            <a mat-button class="white-box w-100 pdfbtn" routerLink="/invoice">
                <mat-icon>picture_as_pdf</mat-icon>
                Download Invoice
            </a>
            <div class="white-box" id="reasonbox" *ngIf="isShown" >
              <mat-form-field class="example-full-width" appearance="outline" class="w-100">
                <mat-label>Reason For Cancelleation</mat-label>
                <textarea matInput #reason class="w-100" required></textarea>
            
              </mat-form-field>
              <button mat-button class="white-box w-100  cancel" (click)="cancelBooking(reason.value,e.t_id,'booking',e.t_driver)">
                Submit
               </button>
            </div>
            <div *ngIf="e.t_trip_status == 'yettostart';">
              <button mat-button class="white-box w-100  cancel" id="cancel" (click)="toggleShow()">
          
                Cancel Booking
            </button>
            </div>
            <div class="white-box" *ngIf="e.t_driver!=''; else elseBlockdriver">
              <h2>Your Driver</h2>
              <table>
              <tr class="badge badge-success mr-2">
                <td class="question">
                  Vehicle Id:
                </td>
                <td>
                  <span>{{e.t_driver}}</span>
                </td>
              </tr>
              <tr class="badge badge-success">
                <td class="question">
                  Driver Name:
                </td>
                <td>
                  <span>{{e.t_driver_name}}</span>
                </td>
              </tr>
            </table>
            <div class="mt-2" id="reasonbox2" *ngIf="isShown2" >
              <mat-form-field class="example-full-width" appearance="outline" class="w-100">
                <mat-label>Reason For Cancelleation</mat-label>
                <textarea matInput #reason class="w-100" required></textarea>
            
              </mat-form-field>
              <button mat-button class="white-box w-100  cancel" (click)="cancelBooking(reason.value,e.t_id,'driver',e.t_driver)">
                Submit
               </button>
            </div>
            <div  *ngIf=" e.t_trip_status == 'yettostart'">
              <button mat-button class="white-box w-100  cancel" id="cancel2" (click)="toggleShow2()">
          
                Cancel Driver
            </button>
            </div>
            </div>
            <ng-template #elseBlockdriver>
              <div  class="white-box">
                <h2>Driver is not assigned</h2>
  
              </div>
            </ng-template>
        </div>
      </div>
    </div>
  </div>
</section>