import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GlobalConstants } from '../global-constants';
declare var $: any;

@Component({
  selector: 'app-detailed-booking-details',
  templateUrl: './detailed-booking-details.component.html',
  styleUrls: ['./detailed-booking-details.component.less']
})
export class DetailedBookingDetailsComponent implements OnInit {
  li: any;
  lis: any;
  pageid:any;
  constructor(private http: HttpClient,private route:Router) { }
  isShown: boolean = false ; 
  isShown2: boolean = false ; 
  ngOnInit(): void {
    this.pageid=localStorage.getItem('t_id');
    $('.preloader').show();
    
    
    
    const headers = { 'Content-type': 'application/json'};
    const body = {
      customer_id: localStorage.getItem("customer_id"),
    };
    const data= {
      customer_id: localStorage.hasOwnProperty("customer_id"),
    };
    this.http.post(GlobalConstants.apiURL+'customerdetails', body, { headers })
      .subscribe((Response: any) => {
        if (Response) {
           console.log(Response);
          $('.preloader').fadeOut();
          this.li=Response.trip.data; 
          this.lis=this.li.list;
        
          }
  
  
      });
  }
  // $("#cancel").click(function () { 

  // });
  checkstops(t_intermediate_stops:any){
    function jsonEscape(str: string)  {
      return str.replace('","', " -> ");
  }
  if(t_intermediate_stops.length>0){
  var dataObj = JSON.parse(jsonEscape(t_intermediate_stops));
    return dataObj;
  }
  }
  addtotal(x:any,y:any){
    return(parseInt(x)+parseInt(y));
  }
  toggleShow() {

    this.isShown = ! this.isShown;
   $('#cancel').hide();
    
    }
    toggleShow2() {

      this.isShown2 = ! this.isShown;
     $('#cancel2').hide();
      
      }
    public cancelBooking(reason:any,t_id:any,type:any,d_id:any){
      if(reason=="" || reason==" " || reason==null){
        $('#reasonbox').addClass('red');
      }
      else{
        $('#reasonbox').removeClass('red');
        $('.preloader').fadeIn();
          const headers = { 'Content-type': 'application/json'};
          const body = {
            t_id: t_id,
            reason:reason,
            type:type,
            d_id:d_id,
          };
          this.http.post(GlobalConstants.apiURL+'canceltrip', body, { headers })
            .subscribe((Response: any) => {
              if (Response) {
                  window.location.reload();
                }
        
        
            });
      }

    }
 
}
