<section class="mybookings pt-5 pb-5">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <h1>My <span>Bookings</span></h1>
            </div>
            <div class="col-12">
              <div class="table-responsive">
                <table class="table table-dark">
                  <thead>
                    <tr>
                      <th scope="col">Date</th>
                      <th scope="col">From</th>
                      <th scope="col">To</th>
                      <th scope="col">Vehicle</th>
                      <th scope="col">Status</th>
                      <th scope="col">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                   <tr *ngFor="let e of li;">
                     <td>
                       {{e.t_created_date | date:'MM/dd/yyyy'}}
                     </td>
                     <td>
                      {{e.t_trip_fromlocation}}
                    </td>
                    <td>
                      {{e.t_trip_tolocation}}
                    </td>
                    <td>
                      {{e.t_vechicle}}
                    </td>
                    <td>


                      <div *ngIf="e.t_trip_status == 'OnGoing';">
                       Ongoing
                      </div>
                       <div *ngIf="e.t_trip_status == 'completed';">
                       Completed
                      </div>
                      <div *ngIf="e.t_trip_status == 'yettostart';">
                        Yet to Start
                       </div>
                       <div *ngIf="e.t_trip_status == 'cancelled';">
                        Cancelled
                       </div>
                       <div *ngIf="e.t_trip_status == 'yettoconfirm';">
                        Yet to Confirm
                       </div>
                    </td>
                    <td>
                      <button mat-button class="view" (click)="view(e.t_id)">View</button>
                    </td>
                   </tr>
                
                  </tbody>
                </table>
             
              </div>
            </div>
        </div>
    </div>
</section>