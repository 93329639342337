import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GlobalConstants } from '../global-constants';
declare var $ :any;
import jspdf from 'jspdf';
import html2canvas from 'html2canvas';

@Component({
  selector: 'app-invoice',
  templateUrl: './invoice.component.html',
  styleUrls: ['./invoice.component.less']
})
export class InvoiceComponent implements OnInit {
  li: any;
  lis: any;
  li2: any;
  lis2: any;
  pageid:any;

  constructor(private http: HttpClient,private route:Router) { }

  ngOnInit(): void {
    
    
    this.pageid=localStorage.getItem('t_id');
    $('.preloader').show();
    const headers = { 'Content-type': 'application/json'};
    const body = {
      customer_id: localStorage.getItem("customer_id"),
    };
    const data= {
      customer_id: localStorage.hasOwnProperty("customer_id"),
    };
    this.http.post(GlobalConstants.apiURL+'customerdetails', body, { headers })
      .subscribe((Response: any) => {
        if (Response) {
           console.log(Response);
          $('.preloader').fadeOut();
          this.li=Response.trip.data; 
          this.lis=this.li.list;
          this.li2=Response.customer; 
          this.lis2=this.li.list;
        
          }
  
  
      });
  }
  print(){
    $('.preloader').show();
    var data = document.getElementById('contentToConvert');
    html2canvas(data).then(canvas => {
      var imgWidth = 208;
      var imgHeight = canvas.height * imgWidth / canvas.width;
      const contentDataURL = canvas.toDataURL('image/png')
      let pdf = new jspdf('p', 'mm', 'a4');
      var position = 0;
      pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight);
      $('.preloader').hide();
      pdf.save('Invoice.pdf');
    });
  }
  addtotal(x:any,y:any){
    return(parseInt(x)+parseInt(y));
  }
  checkstops(t_intermediate_stops:any){
    function jsonEscape(str: string)  {
      return str.replace('","', " -> ");
  }
  if(t_intermediate_stops.length>0){
  var dataObj = JSON.parse(jsonEscape(t_intermediate_stops));
    return dataObj;
  }
  }
}
