import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import {AgmCoreModule} from '@agm/core';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker';
import { environment } from '../environments/environment';
import { HeaderComponent } from './header/header.component';
import { DialogContentExampleDialog } from './header/header.component';
import { DialogContentExampleDialog2 } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { MaterialModule } from './material.module';
import {MatGoogleMapsAutocompleteModule} from '@angular-material-extensions/google-maps-autocomplete';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { AboutComponent } from './about/about.component';
import { TestimonialsComponent } from './testimonials/testimonials.component';
import { MybookingsComponent } from './mybookings/mybookings.component';
import { NgxPayPalModule } from 'ngx-paypal';
import { TermsComponent } from './terms/terms.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { MyprofileComponent } from './myprofile/myprofile.component';

import { LocationStrategy, HashLocationStrategy,DatePipe } from '@angular/common';
import { BookingComponent } from './booking/booking.component'
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { GlobalConstants } from './global-constants';
import { StripeModule } from "stripe-angular";
import { PaymentComponent } from './payment/payment.component';
import { CreditCardDirectivesModule } from 'angular-cc-library';
import { DetailedBookingDetailsComponent } from './detailed-booking-details/detailed-booking-details.component';
import { InvoiceComponent } from './invoice/invoice.component';
import { FavouritesComponent } from './favourites/favourites.component';


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HeaderComponent,
    FooterComponent,
    DialogContentExampleDialog,
    DialogContentExampleDialog2,
    AboutComponent,
    TestimonialsComponent,
    MybookingsComponent,
    TermsComponent,
    PrivacyComponent,
    MyprofileComponent,
    BookingComponent,
    PaymentComponent,
    DetailedBookingDetailsComponent,
    InvoiceComponent,
    FavouritesComponent,
  ],
  imports: [
    NgxMaterialTimepickerModule,
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialModule,
    CarouselModule ,
    NgxPayPalModule,
    FontAwesomeModule,
    CreditCardDirectivesModule,
    AgmCoreModule.forRoot({
      apiKey: GlobalConstants.googleKey,
      libraries: ['places']
    }),
    MatGoogleMapsAutocompleteModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
    StripeModule.forRoot(GlobalConstants.Stripepkey),
  ],
  providers: [DatePipe,{provide: LocationStrategy, useClass: HashLocationStrategy} ],
  bootstrap: [AppComponent]
})
export class AppModule { }
