import { ViewChild } from '@angular/core';
import { AfterViewInit } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import { HttpClient } from '@angular/common/http';
import {MatTableDataSource} from '@angular/material/table';

import {
  IPayPalConfig,
  ICreateOrderRequest 
} from 'ngx-paypal';
import { GlobalConstants } from '../global-constants';
import { Router } from '@angular/router';

declare var $ :any;

export interface PeriodicElement {
  paymenturl: String,
  from: string;
  date: string;
  to: string;
  status: string;
  payment:string;
  
  tableDatas:any;
}


const ELEMENT_DATA: PeriodicElement[] =    [

];

@Component({
  selector: 'app-favourites',
  templateUrl: './favourites.component.html',
  styleUrls: ['./favourites.component.less']
})


export class FavouritesComponent implements AfterViewInit  {
  public payPalConfig ? : IPayPalConfig;
 
  displayedColumns: string[] = ['t_created_date', 't_trip_fromlocation', 't_trip_tolocation', 't_trip_status','tp_payment_status'];
  dataSource = new MatTableDataSource<PeriodicElement>(ELEMENT_DATA);

  @ViewChild(MatPaginator) paginator: MatPaginator;
  li: any;
  lis: any;
  li2: any;
  lis2: any;

  
  constructor(private http: HttpClient,private route:Router) { }
  ngAfterViewInit(): void {
    this.ngOnInit();
    this.dataSource.paginator = this.paginator;
        
    $('.preloader').show();
    
  }

  ngOnInit(): void {
    const headers = { 'Content-type': 'application/json'};
    const body = {
      customer_id: localStorage.getItem("customer_id"),
    };
    const data= {
      customer_id: localStorage.hasOwnProperty("customer_id"),
    };
    this.http.post(GlobalConstants.apiURL+'customerdetails', body, { headers })
      .subscribe((Response: any) => {
        if (Response) {
          $('.preloader').fadeOut();
          console.log(Response);
          this.li=Response.trip.data; 
          this.lis=this.li.list;
          this.li2=Response.f_drivers; 
          this.lis2=this.li2.list;
          
          }
  
  
      });
    
  }
  view(t_id:any){
    localStorage.setItem('t_id', t_id);
    
    this.route.navigate(['/booking-detailed-view']);
  }
  Remove(id:any,type:any){
    
    $('.preloader').show();
    const headers = { 'Content-type': 'application/json'};
    const body = {
      id: id,
      type: type,

    };
    this.http.post(GlobalConstants.apiURL+'removefavourite', body, { headers })
      .subscribe((Response: any) => {
        if (Response) {
          window.location.reload();
          }
      });
  }

}

