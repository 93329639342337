<!-- <h2 mat-dialog-title class="text-center">Login or Register</h2> -->
<mat-dialog-content class="mat-typography">
    <mat-tab-group>
      <mat-tab label="Login">
        <div class="row w-100 text-center p-3 m-0">
          <div class="col-12">
            <mat-form-field appearance="fill" class="w-100">
              <mat-label>Enter your email</mat-label>
              <input matInput placeholder="pat@example.com" [formControl]="email" required>
              <mat-error *ngIf="email.invalid">{{getErrorMessage()}}</mat-error>
            </mat-form-field>
  
          </div>
          <div class="col-12">
            <mat-form-field appearance="fill" class="w-100">
              <mat-label>Enter your password</mat-label>
              <input matInput [type]="hide ? 'password' : 'text'">
              <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'"
                [attr.aria-pressed]="hide">
                <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
              </button>
            </mat-form-field>
          </div>
          <div class="col-12">
            <button mat-raised-button class="btn1">Login</button>
          </div>
        </div>
      </mat-tab>
      <mat-tab label="Register">
        <div class="row w-100 text-center p-3 m-0">
          <div class="col-12">
            <mat-form-field appearance="fill" class="w-100">
              <mat-label>Name</mat-label>
              <input matInput placeholder=""  required>
            </mat-form-field>
  
          </div>
          <div class="col-md-6">
            <mat-form-field appearance="fill" class="w-100">
                <mat-label>Age</mat-label>
                <input matInput placeholder=""  required>
              </mat-form-field>
          </div>
          <div class="col-md-6">
            <mat-form-field appearance="fill" class="w-100">
                <mat-label>License No</mat-label>
                <input matInput placeholder=""  required>
              </mat-form-field>
          </div>
          <div class="col-12">
            <mat-form-field appearance="fill" class="w-100">
              <mat-label>Mobile</mat-label>
              <input matInput placeholder=""  required>
            </mat-form-field>
  
          </div>
          <div class="col-12">
            <mat-form-field appearance="fill" class="w-100">
              <mat-label>Email</mat-label>
              <input matInput placeholder="pat@example.com" [formControl]="email" required>
              <mat-error *ngIf="email.invalid">{{getErrorMessage()}}</mat-error>
            </mat-form-field>
  
          </div>
          <div class="col-12">
            <mat-form-field appearance="fill" class="w-100">
              <mat-label>Password</mat-label>
              <input matInput [type]="hide ? 'password' : 'text'">
              <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'"
                [attr.aria-pressed]="hide">
                <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
              </button>
            </mat-form-field>
          </div>
          <div class="col-12">
            <mat-form-field appearance="fill" class="w-100">
              <mat-label>Address</mat-label>
              <input matInput placeholder=""  required>
            </mat-form-field>
  
          </div>
          <div class="col-12">
            <p>
              By signing up, you agree to our <a href="/terms-conditions">Terms and Conditions</a>.
            </p>
          </div>
          <div class="col-12">
            <button mat-raised-button class="btn1">Sign up</button>
          </div>
        </div>
      </mat-tab>
    </mat-tab-group>
  </mat-dialog-content>
  
  <mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Close</button>
  </mat-dialog-actions>
  