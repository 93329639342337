<section class="payment-page">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <h2 class="mb-0">Enter Your card details</h2>
          <form [formGroup]="demoForm"
                (ngSubmit)="onSubmit(demoForm)"
                novalidate
                style="max-width: 500px; margin: 0 auto; padding-top: 20px;">
            
            <div class="form-group">
              <label for="card-element" class="control-label">Card Details
                <small class="text-muted" *ngIf="type$ | async as type">[<span class="cc-brand">{{type}}</span>]</small>
              </label>
              <div id="card-element" class="form-control"></div>
            </div>
  
            <!-- <div class="form-group">
              <label for="cc-exp" class="control-label">Card expiry formatting</label>
              <input id="cc-exp"
                     formControlName="expDate"
                     type="tel"
                     class="input-lg form-control cc-exp"
                     autocomplete="cc-exp"
                     placeholder="•• / ••">
            </div>
  
            <div class="form-group">
              <label for="cc-cvc" class="control-label">Card CVC formatting</label>
              <input id="cc-cvc"
                     formControlName="cvc"
                     type="tel"
                     class="input-lg form-control cc-cvc"
                     autocomplete="off"
                     placeholder="•••"
                     required>
            </div> -->
  
            <!-- <div class="errors">
              <div *ngIf="!demoForm.controls.creditCard.valid && demoForm.controls.creditCard.dirty" class="alert alert-danger">Credit Card is invalid</div>
              <div *ngIf="!demoForm.controls.expDate.valid && demoForm.controls.expDate.dirty" class="alert alert-danger">Expiration Date is required</div>
              <div *ngIf="!demoForm.controls.cvc.valid && demoForm.controls.cvc.dirty" class="alert alert-danger">CVC is required</div>
            </div>
  
            <div class="form-group">
              <div class="checkbox">
                <label><input type="checkbox" id="save"> Save payment info for easy ordering</label>
              </div>
            </div> -->
  
            <div class="form-group">
              <button mat-raised-button type="submit" class="submit">Pay ${{totalPrice}}</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </section>
  