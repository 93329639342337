<div class="d-block d-md-none" *ngIf="logedin==true;" >
    <div class="pt-5"></div>
    <div class="bottom-nav">
        <a routerLink="./my-bookings" role="button">
            <mat-icon>luggage</mat-icon>
            <span class="label">Bookings</span>
        </a>
       
        <a routerLink="./" role="button">
            <mat-icon>home</mat-icon>
            <span class="label">Home</span>
        </a>
        <a routerLink="./my-profile" role="button">
            <mat-icon>person</mat-icon>
            <span class="label">Profile</span>
        </a>
    </div>
</div>
<div class="d-block d-md-none" *ngIf="logedin==false;" >
    <div class="pt-5"></div>
    <div class="bottom-nav">
        <a  id="" (click)="openDialog()">
            <mat-icon>luggage</mat-icon>
            <span class="label">Bookings</span>
        </a>
        <a routerLink="./" role="button">
            <mat-icon>home</mat-icon>
            <span class="label">Home</span>
        </a>
        <a id="" (click)="openDialog()">
            <mat-icon>person</mat-icon>
            <span class="label">Profile</span>
        </a>
    </div>
</div>
<footer class="bg-dark pt-5 pb-0 blocks d-none d-md-block">
        <div class="container">
            <div class="row">

                <div class="col-md-4">
                    <h3>About</h3>
                    <p>We are a ride-share service. We give anyone a ride who needs one.</p>

                </div>
                <div class="col-md-4">
                    <h3>Links</h3>
                    <ul>
                        <li>
                            <a routerLink="/about">
                                About Us
                            </a>
                        </li>
                        <li>
                            <a routerLink="/testimonials">
                                Testimonials
                            </a>
                        </li>
                        <li>
                            <a href="https://yodertoter.com/docs/privacy.html" target="_blank">
                                Privacy
                            </a>
                        </li>
                        <li>
                            <a routerLink="/terms-conditions">
                                Terms & Conditions
                            </a>
                        </li>
                    </ul>
                </div>
                <div class="col-md-4">
                    <h3>
                        Contact
                    </h3>
                    <p>Berlin, Ohio</p>
                    <ul>
                        <li>
                            <a href="mailto:WeCare@YoderToter.com">
                                <span><mat-icon aria-hidden="false"
                                        aria-label="Example home icon">email</mat-icon></span>
                                WeCare@YoderToter.com
                            </a>
                        </li>
                        <li>
                            <a href="tel: 330-474-9507">
                                <span><mat-icon aria-hidden="false"
                                        aria-label="Example home icon">phone</mat-icon></span>
                                330-474-9507
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>


        <p class="mb-0 color-white footercopy mt-5"> &copy; All rights reserved by Yoder Toter.</p>
    </footer>