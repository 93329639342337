<!-- <h2 mat-dialog-title class="text-center">Login or Register</h2> -->
<mat-dialog-content class="mat-typography">
  <div class="row w-100 text-center p-3 m-0">
    <div class="col-12 col-md-12 rgdn d-none">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>Full Name</mat-label>
        <input matInput placeholder="" #name required>
      </mat-form-field>

    </div>
    <div class="col-12">
     
      <mat-form-field appearance="fill" class="w-100">
        <mat-label>Enter your Phone</mat-label>
        <input matInput placeholder="" maxlength="10" minlength="10" #loginphone required>
      </mat-form-field>

    </div>
    <div class="col-12 col-md-12 rgdn d-none">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>Email</mat-label>
        <input matInput placeholder="pat@example.com" #email [formControl]="email" required>
        <mat-error *ngIf="email.invalid">{{getErrorMessage()}}</mat-error>
      </mat-form-field>

    </div>
    <div class="col-12 lgndt">
      <mat-form-field appearance="fill" class="w-100">
        <mat-label>Enter your password</mat-label>
        <input matInput [type]="hide ? 'password' : 'text'" #loginpassword>
        <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'"
          [attr.aria-pressed]="hide">
          <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
        </button>
      </mat-form-field>
    </div>
    <div class=" col-12 bordered  rgdn d-none" id="otp">
      <div class="row">
          <div class="col-12 col-md-6">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>OTP</mat-label>
              <input matInput placeholder="" #otp required>
            </mat-form-field>
  
          </div>
          <div class="col-12 col-md-6">
            <button mat-raised-button class="btn1 w-100 mt-0" (click)="generateOtp(loginphone.value)">Resend OTP</button>
          </div>
      </div>
    </div>
<div class="col-12">
  <p class="errormod" style="display:none;">
    All fields are required
  </p>
  
</div>
    <div class="col-12">
      <button mat-raised-button class="btn1 lgndt lgndt2" (click)="login(loginphone.value,loginpassword.value)">Login</button>
      <button mat-raised-button class="btn1 rgdn d-none" (click)="register(name.value,loginphone.value,email.value,loginpassword.value,otp.value)">Register</button>
      <button mat-raised-button class="btn1 rcvrybtn fgdt d-none " (click)="forgotPwd(loginphone.value)">Message me recovery password</button>
      
      <button mat-raised-button class=" btn10 lgndt lgndt2" (click)="toggleshow()">Forgot Password?</button>
      <button mat-raised-button class=" btn10 lgndt lgndt2" (click)="toggleshow2()">Don't have an account yet?  Sign Up</button>
      <button mat-raised-button class=" btn10 fgdt d-none" (click)="toggleshow()">Have Password? Login</button>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Close</button>
</mat-dialog-actions>
