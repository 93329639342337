import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { GlobalConstants } from '../global-constants';
import Swal from 'sweetalert2';

declare var $: any;

@Component({
  selector: 'app-myprofile',
  templateUrl: './myprofile.component.html',
  styleUrls: ['./myprofile.component.less']
})
export class MyprofileComponent implements OnInit {
  name:any;
  phone:any;
  email:any;
  address:any;
  hide = true;
  hide2 = true;

  constructor(private http: HttpClient) { }

  ngOnInit(): void {
    $('.preloader').show();
   
    const headers = { 'Content-type': 'application/json'};
    const body = {
      customer_id: localStorage.getItem("customer_id"),
    };
    const data= {
      customer_id: localStorage.hasOwnProperty("customer_id"),
    };
    this.http.post(GlobalConstants.apiURL+'customerdetails', body, { headers })
      .subscribe((Response: any) => {
        if (Response) {
          
      $('.preloader').fadeOut();
          console.log(Response);
          this.name=Response.customer.c_name;
          this.email=Response.customer.c_email;
          this.phone=Response.customer.c_mobile;
          this.address=Response.customer.c_address;
          
          }
  
  
      });
  }
  updateAddress(newaddress:any){
    const headers = { 'Content-type': 'application/json'};
    const body = {
      customer_id: localStorage.getItem("customer_id"),
      address:newaddress,
    };
    this.http.post(GlobalConstants.apiURL+'addressupdate', body, { headers })
      .subscribe((Response: any) => {
        if (Response.statuss="success") {
          window.location.reload();
          
          }
  
  
      });
  }
  changepassword(oldpassword:any,newpassword:any,confirmpassword:any){
    if(oldpassword=="" || oldpassword==null || oldpassword==undefined || oldpassword.length<3){
      $('#pwdupdt p').html('Please check password you entered.');
      $('#pwdupdt').show();
    }
    else if(newpassword<7){
      $('#pwdupdt p').html('Password is too short (atleast 6 characters)');
      $('#pwdupdt').show();
    }
    else if(newpassword != confirmpassword){
      $('#pwdupdt p').html('Please make sure your passwords match.');
      $('#pwdupdt').show();
    }
    else{
      $('#pwdupdt').hide();
      const headers = { 'Content-type': 'application/json'};
      const body = {
        c_id: localStorage.getItem("customer_id"),
        oldpass:oldpassword,
        newpass:confirmpassword,
      };
      this.http.post(GlobalConstants.apiURL+'changepassword', body, { headers })
        .subscribe((Response: any) => {
          console.log(Response);
          console.log(Response.status);
          if (Response.status=="success") {
              $('#pwdupdt').hide();
              Swal.fire({
                title: 'Success',
                text: "Your passwrod updated.",
                icon: 'success',
                confirmButtonText: 'OK'
              }).then((result) => {
                if (result.isConfirmed) {
                  window.location.reload();
                }
              })
            }
          else{
            $('#pwdupdt p').html(Response.message);
            $('#pwdupdt').show();
          }
    
    
        });
    }
  }

}
