<section class="terms pt-5 pb-5">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <h1>Privacy</h1>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc at
            ligula quis nibh venenatis gravida. Lorem ipsum dolor sit amet,
            consectetur adipiscing elit. Pellentesque orci erat, scelerisque eget
            diam ac, mattis bibendum magna. Vivamus elementum elit sed lacus
            malesuada porttitor. Vivamus aliquet sodales eros a volutpat. Donec
            gravida neque tincidunt tristique convallis. Quisque tellus nunc,
            efficitur dignissim pulvinar ut, tincidunt a erat. Nulla placerat
            massa ut ante hendrerit ornare eu et eros. Mauris sed massa lectus.
            Maecenas imperdiet odio vel urna ornare vestibulum. Cras hendrerit
            eros quis orci rutrum luctus. Donec in enim vel nulla lobortis
            pharetra ut quis ipsum. Nunc molestie sagittis sem venenatis
            tincidunt. Suspendisse convallis rhoncus dignissim. Donec ut orci a
            elit efficitur iaculis vitae varius orci. Vivamus at arcu risus.
          </p>
          <p>
            Maecenas posuere faucibus diam, eget porttitor lorem aliquam sit amet.
            Donec eget facilisis enim. Maecenas elementum tempus nunc sed semper.
            Vivamus mi ante, accumsan ut felis in, fermentum pretium ex. Ut lacus
            ex, pharetra vitae est a, bibendum viverra augue. Vestibulum dictum
            arcu quis augue varius dapibus. Suspendisse lobortis pretium ligula
            sed egestas. Aenean eget tempus odio. Ut fringilla interdum risus, ut
            dictum tortor viverra ut.
          </p>
          <p>
            Proin facilisis ipsum neque, et dignissim orci suscipit sit amet. Cras
            at ligula at velit congue cursus eu vel lectus. Curabitur commodo leo
            et libero elementum volutpat. Donec laoreet mi molestie velit maximus,
            sed congue mauris faucibus. Pellentesque quis ex pretium, semper metus
            sed, auctor dui. Curabitur aliquet justo non massa posuere, ut
            condimentum tortor convallis. Integer in luctus elit, at tincidunt
            leo. Nunc est est, tincidunt vel nunc a, bibendum pharetra augue.
            Integer a egestas nulla. Sed euismod libero id sem porta interdum.
            Curabitur cursus, orci vitae fringilla congue, massa elit porttitor
            est, laoreet maximus nibh nibh vel ex. Interdum et malesuada fames ac
            ante ipsum primis in faucibus. Curabitur auctor a dui quis tincidunt.
            Sed ut dictum enim.
          </p>
          <p>
            Aliquam erat volutpat. Morbi ut ullamcorper turpis. Sed a ipsum
            scelerisque, imperdiet mauris non, egestas tortor. Sed bibendum velit
            ac nibh lacinia, at rhoncus elit congue. Phasellus suscipit posuere
            risus. Sed at est ultrices, porta eros eget, facilisis massa.
            Pellentesque eu vestibulum nisl. Nam orci leo, tincidunt cursus
            pharetra non, condimentum eu metus. Ut ac massa ut quam feugiat
            vestibulum vitae non turpis.
          </p>
          <p>
            Mauris consequat erat vel enim bibendum pellentesque. Nullam blandit
            lorem sed urna faucibus, vel vestibulum augue vulputate. Nulla
            fermentum interdum massa, a facilisis turpis cursus ac. Aenean
            vehicula lectus ante, in volutpat mi molestie vitae. Integer suscipit
            scelerisque quam. Suspendisse gravida elit ut metus aliquet, non
            posuere elit finibus. Cras ut consectetur nisl, quis hendrerit ligula.
            Donec eget finibus eros. Aliquam erat volutpat. Curabitur ac nisi ut
            risus porttitor feugiat.
          </p>
        </div>
      </div>
    </div>
  </section>
  