<section class="mybookings pt-5 pb-5">
    <div class="container">
        <div class="row">
            <div class="col-md-6">
                <h1>Favourite Trips</h1>
         
              <div class="table-responsive">
                <table class="table table-dark">
                  <thead>
                    <tr>
                      <th scope="col">Date</th>
                      <th scope="col">From</th>
                      <th scope="col">To</th>
                      <th scope="col">Actions</th>
                    </tr>
                  </thead>
                   <tbody *ngFor="let e of li;">
                    <tr *ngIf="e.t_favourite=='1'">
                     <td>
                       {{e.t_created_date | date:'MM/dd/yyyy'}}
                     </td>
                     <td>
                      {{e.t_trip_fromlocation}}
                    </td>
                    <td>
                      {{e.t_trip_tolocation}}
                    </td>
                  
                    <td>
                      <button mat-button class="remove" (click)="Remove(e.t_id,'trip')">
                        Delete
                      </button>
                    </td>
                   </tr>
                </tbody>
                </table>
             
              </div>
            </div>
        <!-- </div>
        <div class="row pt-5"> -->
          <div class="col-md-6">
              <h1>Favourite Drivers</h1>
     
            <div class="table-responsive">
              <table class="table table-dark">
                <thead>
                  <tr>
                    <th scope="col">ID</th>
                    <th scope="col">Name</th>
                    <th scope="col">Vehicle Type</th>
                    <th scope="col">Actions</th>
                  </tr>
                </thead>
                 <tbody *ngFor="let e of li2;">
                  <tr>
                   <td>
                     {{e.d_id }}
                   </td>
                   <td>
                    {{e.d_name}}
                  </td>
                  <td>
                    {{e.d_vehicle}}
                  </td>
                  <td>
                    <button mat-button class="remove" (click)="Remove(e.df_id,'driver')">
                      Delete
                    </button>
                  </td>
                 </tr>
              </tbody>
              </table>
           
            </div>
          </div>
      </div>
    </div>
</section>