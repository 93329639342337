import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.less']
})
export class FooterComponent implements OnInit {

  logedin:boolean;
  constructor() { }

  ngOnInit(): void {
    
    this.checkLoggedin();
  }

  public checkLoggedin(){
    if (localStorage.getItem("customer_id") === null) {
      this.logedin=false;
    }
    else{
      this.logedin=true;
    }
  }
  openDialog() {
    const customerLoginButton = document.getElementById('customerlogin');
    if (customerLoginButton) {
        customerLoginButton.click();
    } 
  }

}
