<section class="testimonials pt-5 pb-5" id="testimonials">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <h2>Testimonials</h2>
            </div>
            <div class="col-12">
                <owl-carousel-o [options]="customOptions">
                  <ng-template carouselSlide>
                    <div class="testi">
                        <p>
                          "We had to get to a local hospital and Yoder Toter was able to help us out." 
                        </p>
                    </div>
                </ng-template>  
                <ng-template carouselSlide>
                  <div class="testi">
                     <p>
                      "Planned a great day of shopping and Yoder Toter made it easy." 
                     </p>
                  </div>
                </ng-template>  
                <ng-template carouselSlide>
                  <div class="testi">
                      <p>
                        "We had to get to a local hospital and Yoder Toter was able to help us out." 
                      </p>
                  </div>
                </ng-template> 
                </owl-carousel-o>
            </div>
        </div>
    </div>
</section>