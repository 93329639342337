import { Component, NgModule, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import {
  Location,
  Appearance,
  GermanAddress,
} from '@angular-material-extensions/google-maps-autocomplete';
import PlaceResult = google.maps.places.PlaceResult;
import { CarouselComponent, OwlOptions } from 'ngx-owl-carousel-o';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import Swal from 'sweetalert2';
import { GlobalConstants } from '../global-constants';

declare var $: any;
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.less'],
})
export class HomeComponent implements OnInit {
  addstops: String;
  tripname: any;
  favourite:false;
  isShown: boolean = false ; 
  li: any;
  lis: any;
  tabind: number;
   logedin:boolean;
 
  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: true,
    navSpeed: 700,
    navText: ['', ''],
    responsive: {
      0: {
        items: 1,
      },
    },
    nav: true,
  };
  public addresses: any[] = [
    {
      id: 1,
      address: '',
    },
  ];
  minDate: Date;
  topsCount = 0;
  constructor(private route:Router,private http: HttpClient) {}

  ngOnInit() {
    this.minDate = new Date();
    this.addstops= "0";
    this.checkLoggedin();
    $("#error").hide();
    this.tabind=0;
  }
  public checkLoggedin(){
    if (localStorage.getItem("customer_id") === null) {
      this.logedin=false;
    }
    else{
      this.logedin=true;
    }
  }
  addAddress() {
    this.addresses.push({
      id: this.addresses.length + 1,
      address: '',
    });
    // alert( (<HTMLInputElement>document.getElementById("mat-input-9")).value);
  }

  removeAddress(i: number) {
    this.addresses.splice(i, 1);
  }

  public driverreg() {
    let element: HTMLElement = document.getElementById(
      'driverlogin'
    ) as HTMLElement;
    element.click();
  }
  public callFavourite(){
    if(localStorage.hasOwnProperty('customer_id')){
      $('.preloader').show();
      const headers = { 'Content-type': 'application/json'};
    const body = {
      customer_id: localStorage.getItem("customer_id"),
    };
    const data= {
      customer_id: localStorage.hasOwnProperty("customer_id"),
    };
    this.http.post(GlobalConstants.apiURL+'customerdetails', body, { headers })
      .subscribe((Response: any) => {
        if (Response) {
            $('.preloader').hide();
            this.li=Response.trip.data; 
            this.lis=this.li.list;
            this.isShown=true;
          }
  
  
      });
    }
    else{

    }
    
  }
  public favchanged(favslct:any){
    $('.preloader').show();
      const headers = { 'Content-type': 'application/json'};
    const body = {
      customer_id: localStorage.getItem("customer_id"),
    };
    const data= {
      customer_id: localStorage.hasOwnProperty("customer_id"),
    };
    this.http.post(GlobalConstants.apiURL+'customerdetails', body, { headers })
      .subscribe((Response: any) => {
        if (Response) {
          
          $('.preloader').fadeOut();
          for( var i=0; i<Response.trip.data.length; i++){
            if(Response.trip.data[i].t_id==favslct){
              let mydata = Response.trip.data[i];

              console.log(mydata.t_type);
              if(mydata.t_type=="outstation"){
                this.tabind=2;
              }
              else if(mydata.t_type=="roundtrip"){
                this.tabind=1;
              }
              else if(mydata.t_type=="oneway"){
                this.tabind=0;
              }
              else{
                this.tabind=0;
              }
              let tempstops=[];
              let tempwaiting=[];
              if(mydata.t_intermediate_stops!=""){
              tempstops=JSON.parse(mydata.t_intermediate_stops);
              tempwaiting=JSON.parse(mydata.t_stop_waiting);
              }
            
              let stopsdata2 = {
                data: [],
              };
              if(tempstops.length>0){
                this.addstops='1';
                for(let i=0; i<tempstops.length; i++){
                  stopsdata2['data'].push({
                    'stop-number': i,
                    'stop_name': tempstops[i],
                    'stop_waiting': tempwaiting[i],
                  })
                }
              }
              else{
                this.addstops='0';
              }
              if(mydata.t_intermediate_stops!=""){
                this.addresses=stopsdata2.data;
              }
              this.isShown=false;
              $('.formfrom').val(mydata.t_trip_fromlocation);
                $('.formto').val(mydata.t_trip_tolocation);
              setTimeout(function(){
                $('.formfrom').val(mydata.t_trip_fromlocation);
                $('.formto').val(mydata.t_trip_tolocation);
                this.isShown=false;
                console.log(stopsdata2.data);
               }, 2000);
            }
          }
           
          }
  
  
      });
  }
  public preBook(from: any, to: any, addstops: any, triptype: any,booktype:any) {
    if(from==""|| from==undefined || from==null || to==""|| to==undefined || to==null){
      $("#error").show();
    }
    else{
      $("#error").hide();
      let stopsdata = {
        data: [],
      };
      if(addstops=="1"){
        for (let count = 1; this.addresses.length >= count; count = count + 1) {
          if((<HTMLInputElement>document.getElementById('stopname' + count)).value =="" || 
          (<HTMLInputElement>document.getElementById('stopname' + count)).value == undefined ||
          (<HTMLInputElement>document.getElementById('stopname' + count)).value == null){
            $("#error").show();
            return; 
            
          }
          else{
            $("#error").hide();
          stopsdata['data'].push({
            'stop-number': count,
            'stop_name': (<HTMLInputElement>document.getElementById('stopname' + count)).value,
            'stop_waiting': $("#stopwait"+count+" select").val(),
          });
          }
        }
      }
      
      if(this.favourite==false || this.favourite==undefined){
        localStorage.setItem('favourite','false');
        localStorage.removeItem("favourite");
        localStorage.removeItem("tripname");
      }
      else{
        localStorage.setItem('favourite','true');
        localStorage.setItem('tripname',this.tripname)
      }
      let schedule_book = {
        data: [
          {
            from: from,
            to: to,
            havestops: addstops,
            stops: stopsdata.data,
            trip_type: triptype,
            booking_type:booktype,
          },
        ],
      };
      localStorage.setItem('booking_step1', JSON.stringify(schedule_book));
      this.route.navigate(['/booking']);
    }
  }
  public preBook2(from: any, to: any, addstops: any, triptype: any,booktype:any) {
    if(from==""|| from==undefined || from==null || to==""|| to==undefined || to==null){
      $("#error").show();
    }
    else{
      $("#error").hide();
      let stopsdata = {
        data: [],
      };
      if(addstops=="1"){
        
        for (let count = 1; this.addresses.length >= count; count = count + 1) {
          stopsdata['data'].push({
            'stop-number': count,
            'stop_name': (<HTMLInputElement>document.getElementById('stopnameb' + count)).value,
            'stop_waiting': $("#stopwaitb"+count+" select").val(),
          });
        }
      }
      if(this.favourite==false || this.favourite==undefined){
        localStorage.setItem('favourite','false');
        localStorage.removeItem("favourite");
        localStorage.removeItem("tripname");
      }
      else{
        localStorage.setItem('favourite','true');
        localStorage.setItem('tripname',this.tripname)
      }
  
      let schedule_book = {
        data: [
          {
            from: from,
            to: to,
            havestops: addstops,
            stops: stopsdata.data,
            trip_type: triptype,
            booking_type:booktype,
          },
        ],
      };
      localStorage.setItem('booking_step1', JSON.stringify(schedule_book));
      this.route.navigate(['/booking']);
    }
  }
  public preBook3(from: any, to: any, addstops: any, triptype: any,booktype:any) {
    if(from==""|| from==undefined || from==null || to==""|| to==undefined || to==null){
      $("#error").show();
    }
    else{
      $("#error").hide();
      let stopsdata = {
        data: [],
      };
      if(addstops=="1"){
        
        for (let count = 1; this.addresses.length >= count; count = count + 1) {
          stopsdata['data'].push({
            'stop-number': count,
            'stop_name': (<HTMLInputElement>document.getElementById('stopnamec' + count)).value,
            'stop_waiting': $("#stopwaitc"+count+" select").val(),
          });
        }
      }
      if(this.favourite==false || this.favourite==undefined){

        localStorage.setItem('favourite','false');
        localStorage.removeItem("favourite");
        localStorage.removeItem("tripname");
      }
      else{
        localStorage.setItem('favourite','true');
        localStorage.setItem('tripname',this.tripname)
      }
  
      let schedule_book = {
        data: [
          {
            from: from,
            to: to,
            havestops: addstops,
            stops: stopsdata.data,
            trip_type: triptype,
            booking_type:booktype,
          },
        ],
      };
      localStorage.setItem('booking_step1', JSON.stringify(schedule_book));
      this.route.navigate(['/booking']);
    }
  }
}