import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component, OnInit, AfterViewInit } from '@angular/core';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { CreditCardValidators, CreditCard } from 'angular-cc-library';
import { defer } from 'rxjs';
import { map } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { GlobalConstants } from '../global-constants';
import { loadStripe, Stripe, StripeElements, StripeCardElement } from '@stripe/stripe-js';
declare var $: any;

@Component({
  selector: 'app-payment',
  templateUrl: './paymentStripe.component.html',
  styleUrls: ['./payment.component.less']
})
export class PaymentComponent implements OnInit, AfterViewInit {
  totalPrice: any;
  savecard: any;
  public demoForm: FormGroup;
  public submitted = false;
  private stripe: Stripe | null = null;
  private elements: StripeElements | null = null;
  private cardElement: StripeCardElement | null = null;

  public type$ = defer(() => this.demoForm.get('creditCard').valueChanges)
    .pipe(map((num: string) => CreditCard.cardType(num)));

  constructor(private fb: FormBuilder, private route: Router, private http: HttpClient, private ref: ChangeDetectorRef, public datepipe: DatePipe) {}

  public ngOnInit() {
    let tt_temp = JSON.parse(localStorage.getItem("booking") || '{}');
    // console.log("tt_temp");
    // console.log(tt_temp.data[0].total_price);
    this.totalPrice = tt_temp.data[0].total_price;
    this.demoForm = this.fb.group({
      creditCard: ['', [CreditCardValidators.validateCCNumber]],
      expDate: ['', [CreditCardValidators.validateExpDate]],
      cvc: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(4)]],
    });
    this.savecard = false;
    this.getSavedcard();
  }

  public ngAfterViewInit() {
    this.initializeStripe();
  }

  private async initializeStripe(): Promise<void> {
    this.stripe = await loadStripe(GlobalConstants.Stripepkey); // Replace with your Stripe publishable key
    if (this.stripe) {
      this.elements = this.stripe.elements();
      this.createCardElement();
    }
  }

  private createCardElement(): void {
    if (this.elements) {
      this.cardElement = this.elements.create('card', {
        hidePostalCode: true // This hides the postal code input
    });
      this.cardElement.mount('#card-element');
    }
  }

  public getSavedcard() {
    $('.preloader').show();

    const headers = { 'Content-type': 'application/json' };
    const body = {
      customer_id: localStorage.getItem("customer_id"),
    };

    this.http.post(GlobalConstants.apiURL + 'customerdetails', body, { headers })
      .subscribe((Response: any) => {
        if (Response) {
          console.log(Response);
          if (Response.card.status == "error") {
            $('.preloader').fadeOut();
          } else {
            $('#cc-number').val(Response.card.card_number);
            let expiry: any;
            expiry = Response.card.card_month + "/" + Response.card.card_year;
            $('#cc-exp').val(expiry);
            $('#cc-cvc').val(Response.card.card_cvc);
            $('#save').prop('checked', true);
            $('.preloader').fadeOut();
          }
        }
      });
  }

  public goToNextField(controlName: string, nextField: HTMLInputElement) {
    if (this.demoForm.get(controlName)?.valid) {
      nextField.focus();
    }
  }

  public async onSubmit(demoForm: FormGroup) {
    $('.preloader').show();
    if(this.submitted==false){
    this.submitted = true;
      if (this.cardElement && this.stripe) {
        const { token, error } = await this.stripe.createToken(this.cardElement);
        if (error) {
          console.error('Error:', error);
          $('.preloader').hide();
          Swal.fire({
            title: 'Error',
            text: error.message,
            icon: 'error',
            confirmButtonText: 'OK'
          });
          this.submitted=false;
          return;
        }

        let bookstep2 = JSON.parse(localStorage.getItem("booking") || '{}');
        let cus_id = localStorage.getItem("customer_id") || '{}';
        let cus_nm = localStorage.getItem("customer_name") || '{}';

        let formadata = {
          creditCard: token.card ? token.card.last4 : '',
          expDate: token.card ? `${token.card.exp_month}/${token.card.exp_year}` : '',
          cvc: this.demoForm.get('cvc')?.value || ''
        };

        this.savecard = $('#save').prop("checked") == true;

        
        console.log("formadata");
        console.log(formadata);
        console.log("Token");
        console.log(token);

        const headers = { 'Content-type': 'application/json' };
        const body = {
          title: 'Booking',
          bookingdata: bookstep2,
          customer_id: cus_id,
          customer_name: cus_nm,
          card_details: formadata,
          token: token.id, // Pass the Stripe token
          payable: this.totalPrice,
          save: this.savecard,
          favouritetrip: localStorage.getItem("favourite") || '',
          tripname: localStorage.getItem("tripname") || '',
        };

        this.http.post(GlobalConstants.apiURL + 'paidbooking', body, { headers })
          .subscribe((Response: any) => {
            $('.preloader').hide();
            if (Response.status == "success") {
              Swal.fire({
                title: 'Success',
                text: "You will receive your ride confirmation and driver details in the registered phone number.",
                icon: 'success',
                confirmButtonText: 'OK'
              }).then((result) => {
                if (result.isConfirmed) {
                  this.route.navigate(['/my-bookings'])
                    .then(() => {
                      window.location.reload();
                    });
                }
              });
            } else {
              Swal.fire({
                title: 'Error',
                text: Response.message,
                icon: 'error',
                confirmButtonText: 'OK'
              }).then((result) => {
                if (result.isConfirmed) {
                  this.route.navigate(['/my-bookings'])
                    .then(() => {
                      window.location.reload();
                    });
                }
              });
            }
          });
      }
    }
  }
}
