import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FormControl, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { GlobalConstants } from '../global-constants';

declare var $: any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.less']
})
export class HeaderComponent implements OnInit {
  logedin: boolean = false;
  cuname: string;

  constructor(public dialog: MatDialog) {}

  ngOnInit(): void {
    $(document).ready(() => {
      if ($(window).width() < 767) {
        $('.start-header .navurl').click((e: any) => { 
          e.preventDefault();
          $('.navbar-toggler').trigger('click');
        });
      }
    });
    this.checkLoggedin();
  }

  logout(): void {
    localStorage.removeItem('customer_id');
    localStorage.removeItem('customer_name');
    window.location.replace('https://yodertoter.com/');
  }

  checkLoggedin(): void {
    const customerId = localStorage.getItem('customer_id');
    this.logedin = customerId !== null;
    if (this.logedin) {
      this.cuname = localStorage.getItem('customer_name') || '';
    }
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(DialogContentExampleDialog);
    dialogRef.afterClosed().subscribe(result => console.log(`Dialog result: ${result}`));
  }

  openDialog2(): void {
    const dialogRef = this.dialog.open(DialogContentExampleDialog2);
    dialogRef.afterClosed().subscribe(result => console.log(`Dialog result: ${result}`));
  }
}

@Component({
  selector: 'customer-login',
  templateUrl: 'customer-login.html',
  styleUrls: ['./header.component.less']
})
export class DialogContentExampleDialog {
  hide = true;
  email = new FormControl('', [Validators.required, Validators.email]);
  otp: any;
  otpShow: boolean;

  constructor(private route: Router, private http: HttpClient) {}
  ngOnInit(): void {
    this.otpShow=false;
    $("#otp").hide();
  }
  getErrorMessage(): string {
    if (this.email.hasError('required')) return 'You must enter a value';
    return this.email.hasError('email') ? 'Not a valid email' : '';
  }

  login(phone: string, password: string): void {
    if (!phone || !password) {
      $(".errormod").html("All fields are required").show();
      return;
    }
    $(".errormod").hide();

    const body = {
      title: 'Login',
      logindat: { data: [{ phone, password }] }
    };
    this.http.post(GlobalConstants.apiURL + 'checkcustomer', body, { headers: { 'Content-type': 'application/json' } })
      .subscribe((response: any) => {
        if (response.status === 'success') {
          localStorage.setItem('customer_id', response.customer_id);
          localStorage.setItem('customer_name', response.customer_name);
          window.location.reload();
        } else {
          $(".errormod").html("Please check phone and password").show();
        }
      });
  }

  forgotPwd(phone: string): void {
    if (!phone) {
      $(".errormod").html("All fields are required").show();
      return;
    }
    $(".errormod").hide();

    const body = {
      title: 'Forgot Password',
      phone
    };
    this.http.post(GlobalConstants.apiURL + 'resetpassword', body, { headers: { 'Content-type': 'application/json' } })
      .subscribe((response: any) => {
        if (response.status === 'success') {
          Swal.fire({
            title: 'Success',
            text: "We have sent you an SMS with a temporary password.",
            icon: 'success',
            confirmButtonText: 'OK'
          });
        } else {
          $(".errormod").html("Please check phone number").show();
        }
      });
  }

  toggleshow(): void {
    $('.lgndt, .fgdt').toggleClass('d-none');
  }
  toggleshow2(): void {
    $('.lgndt2').addClass('d-none');
    $('.rgdn').removeClass('d-none');
  }

  public generateOtp(mobile:any){
    this.otp = Math.floor(100000 + Math.random() * 900000);
    // console.log(this.otp);
    
    // mobile=''+GlobalConstants.countrycode+''+mobile;
    mobile=mobile;
    const headers = { 'Content-type': 'application/json'};
    const body = {
      title: 'otp',
      message: ''+this.otp+' is your OTP for Yoder Toter',
      customer_phone:mobile,
    };
    this.http.post(GlobalConstants.apiURL+'sendsms', body, { headers })
      .subscribe((Response: any) => {
        if (Response) {
          console.log(Response);
          if(Response.status=="success"){
       
          }
        }


      });
  }
  public checkOTP(mobile: any, otp: any, callback: (result: boolean) => void) {
    const headers = { 'Content-type': 'application/json' };
    const body = {
        mobile: mobile,
        otp: otp
    };
    
    this.http.post(GlobalConstants.apiURL + 'otp_check', body, { headers })
        .subscribe((Response: any) => {
            if (Response && Response.status === "success") {
                callback(true);
            } else {
                callback(false);
            }
        }, () => {
            callback(false); // Handle error case
        });
}

  public register(name:any,mobile:any,email:any,password:any,otpnew:any){
    let phnvalid= validatePhone(mobile);
    function validatePhone(number:any){
      var phoneNumberPattern = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;  
      return phoneNumberPattern.test(number); 
   }

   this.checkOTP(mobile, otpnew, (isValid) => {
    if(this.otpShow==false){
      $(".errormod").hide();
      this.generateOtp(mobile);
      this.otpShow=true;
      $("#otp").show();
    }
    else if (!isValid) {
      $(".errormod").html("Wrong OTP");
      $(".errormod").show();
    } else {
     
   
 
    if(name==""|| name==null || name==undefined || mobile==""|| mobile==null || mobile==undefined || email==""|| email==null || email==undefined || password==""|| password==null || password==undefined  ){
      $(".errormod").html(" All fields are required");
      $(".errormod").show();
    }
    else if(phnvalid==false){
      $(".errormod").html(" Please check your phone number");
      $(".errormod").show();
    }
    
    else{
      $(".errormod").hide();

      let regdata = {
        data: [
          {
            name: name,
            mobile: mobile,
            email: email,
            password: password,
          },
        ],
      };
      console.log(regdata);
      const headers = { 'Content-type': 'application/json'};
    const body = {
      title: 'Register',
      regdata: regdata,
    };
    this.http.post(GlobalConstants.apiURL+'register', body, { headers })
      .subscribe((Response: any) => {
        if (Response) {
          console.log(Response);
          if(Response.status=="success"){
            localStorage.setItem('customer_id', Response.customer_id);
            localStorage.setItem('customer_name', Response.customer_name);
            window.location.reload();
          }
          else{
            $(".errormod").html(Response.message);

            $(".errormod").show();
          }
          }


      });
    }
  }
});
  }
}

@Component({
  selector: 'driver-login',
  templateUrl: 'driver-login.html',
  styleUrls: ['./header.component.less']
})
export class DialogContentExampleDialog2 {
  hide = true;
  email = new FormControl('', [Validators.required, Validators.email]);

  getErrorMessage(): string {
    if (this.email.hasError('required')) return 'You must enter a value';
    return this.email.hasError('email') ? 'Not a valid email' : '';
  }
}
