<section class="bookin-form-page pt-5 pb-5">
  <div class="container">
  
   <div class="row">
     <div class="col-md-7 stickyside">
      <div class="row">
        <div class="col-12 text-left">
          <h1 class="text-left">Complete your <span>booking</span></h1>
        </div>
      </div>
      <mat-card class="pt-5 pb-5">
        <div class="bordered">
        <div class="row" id="scheduled">
          <div class="col-md-6">
            
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>Pick Up Date</mat-label>
              <input matInput #date [matDatepicker]="picker" [min]="minDate"  (click)="picker.open()">
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
          </div>
          <div class="col-md-6">
            <mat-form-field appearance="outline"  class="w-100">
              <mat-label >Pick Up Time</mat-label>
              <mat-select #time >
                <mat-option value="00:00">12.00 AM</mat-option>
                <mat-option value="00:15">12.15 AM</mat-option>
                <mat-option value="00:30">12.30 AM</mat-option>
                <mat-option value="00:45">12.45 AM</mat-option>
                <mat-option value="01:00">01.00 AM</mat-option>
                <mat-option value="01:15">01.15 AM</mat-option>
                <mat-option value="01:30">01.30 AM</mat-option>
                <mat-option value="01:45">01.45 AM</mat-option>
                <mat-option value="02:00">02.00 AM</mat-option>
                <mat-option value="02:15">02.15 AM</mat-option>
                <mat-option value="02:30">02.30 AM</mat-option>
                <mat-option value="02:45">02.45 AM</mat-option>
                <mat-option value="03:00">03.00 AM</mat-option>
                <mat-option value="03:15">03.15 AM</mat-option>
                <mat-option value="03:30">03.30 AM</mat-option>
                <mat-option value="03:45">03.45 AM</mat-option>
                <mat-option value="04:00">04.00 AM</mat-option>
                <mat-option value="04:15">04.15 AM</mat-option>
                <mat-option value="04:30">04.30 AM</mat-option>
                <mat-option value="04:45">04.45 AM</mat-option>
                <mat-option value="05:00">05.00 AM</mat-option>
                <mat-option value="05:15">05.15 AM</mat-option>
                <mat-option value="05:30">05.30 AM</mat-option>
                <mat-option value="05:45">05.45 AM</mat-option>
                <mat-option value="06:00">06.00 AM</mat-option>
                <mat-option value="06:15">06.15 AM</mat-option>
                <mat-option value="06:30">06.30 AM</mat-option>
                <mat-option value="06:45">06.45 AM</mat-option>
                <mat-option value="07:00">07.00 AM</mat-option>
                <mat-option value="07:15">07.15 AM</mat-option>
                <mat-option value="07:30">07.30 AM</mat-option>
                <mat-option value="07:45">07.45 AM</mat-option>
                <mat-option value="08:00">08.00 AM</mat-option>
                <mat-option value="08:15">08.15 AM</mat-option>
                <mat-option value="08:30">08.30 AM</mat-option>
                <mat-option value="08:45">08.45 AM</mat-option>
                <mat-option value="09:00">09.00 AM</mat-option>
                <mat-option value="09:15">09.15 AM</mat-option>
                <mat-option value="09:30">09.30 AM</mat-option>
                <mat-option value="09:45">09.45 AM</mat-option>
                <mat-option value="10:00">10.00 AM</mat-option>
                <mat-option value="10:15">10.15 AM</mat-option>
                <mat-option value="10:30">10.30 AM</mat-option>
                <mat-option value="10:45">10.45 AM</mat-option>
                <mat-option value="11:00">11.00 AM</mat-option>
                <mat-option value="11:15">11.15 AM</mat-option>
                <mat-option value="11:30">11.30 AM</mat-option>
                <mat-option value="11:45">11.45 AM</mat-option>
                <mat-option value="12:00">12.00 PM</mat-option>
                <mat-option value="12:15">12.15 PM</mat-option>
                <mat-option value="12:30">12.30 PM</mat-option>
                <mat-option value="12:45">12.45 PM</mat-option>
                <mat-option value="13:00">01.00 PM</mat-option>
                <mat-option value="13:15">01.15 PM</mat-option>
                <mat-option value="13:30">01.30 PM</mat-option>
                <mat-option value="13:45">01.45 PM</mat-option>
                <mat-option value="14:00">02.00 PM</mat-option>
                <mat-option value="14:15">02.15 PM</mat-option>
                <mat-option value="14:30">02.30 PM</mat-option>
                <mat-option value="14:45">02.45 PM</mat-option>
                <mat-option value="15:00">03.00 PM</mat-option>
                <mat-option value="15:15">03.15 PM</mat-option>
                <mat-option value="15:30">03.30 PM</mat-option>
                <mat-option value="15:45">03.45 PM</mat-option>
                <mat-option value="16:00">04.00 PM</mat-option>
                <mat-option value="16:15">04.15 PM</mat-option>
                <mat-option value="16:30">04.30 PM</mat-option>
                <mat-option value="16:45">04.45 PM</mat-option>
                <mat-option value="17:00">05.00 PM</mat-option>
                <mat-option value="17:15">05.15 PM</mat-option>
                <mat-option value="17:30">05.30 PM</mat-option>
                <mat-option value="17:45">05.45 PM</mat-option>
                <mat-option value="18:00">06.00 PM</mat-option>
                <mat-option value="18:15">06.15 PM</mat-option>
                <mat-option value="18:30">06.30 PM</mat-option>
                <mat-option value="18:45">06.45 PM</mat-option>
                <mat-option value="19:00">07.00 PM</mat-option>
                <mat-option value="19:15">07.15 PM</mat-option>
                <mat-option value="19:30">07.30 PM</mat-option>
                <mat-option value="19:45">07.45 PM</mat-option>
                <mat-option value="20:00">08.00 PM</mat-option>
                <mat-option value="20:15">08.15 PM</mat-option>
                <mat-option value="20:30">08.30 PM</mat-option>
                <mat-option value="20:45">08.45 PM</mat-option>
                <mat-option value="21:00">09.00 PM</mat-option>
                <mat-option value="21:15">09.15 PM</mat-option>
                <mat-option value="21:30">09.30 PM</mat-option>
                <mat-option value="21:45">09.45 PM</mat-option>
                <mat-option value="22:00">10.00 PM</mat-option>
                <mat-option value="22:15">10.15 PM</mat-option>
                <mat-option value="22:30">10.30 PM</mat-option>
                <mat-option value="22:45">10.45 PM</mat-option>
                <mat-option value="23:00">11.00 PM</mat-option>
                <mat-option value="23:15">11.15 PM</mat-option>
                <mat-option value="23:30">11.30 PM</mat-option>
                <mat-option value="23:45">11.45 PM</mat-option>

              </mat-select>
            </mat-form-field>

                <!-- <div class="min-time-example">
                  <input placeholder="Starting Time"
                      [ngxTimepicker]="min" 
                      [min]="minTime | date:'shortTime'"   
                      class="timepkr" #time  readonly>
                  <ngx-material-timepicker #min></ngx-material-timepicker>
              </div> -->
          </div>
        </div>
        <div class="row">
          <div class="col-md-3"></div>
          <div class="col-md-12" *ngIf="ttype === 'roundtrip'"></div>
          <div class="col-md-6" *ngIf="ttype === 'roundtrip'">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>Round Trip Waiting</mat-label>
              <mat-select [(value)]="roundtripWaiting" #roundtripWaitings (selectionChange)="onroundtripWaitingchange(roundtripWaitings.value)">
                <mat-option value="15">15 Minutes</mat-option>
                <mat-option value="30">30 Minutes</mat-option>
                <mat-option value="45">45 Minutes</mat-option>
                <mat-option value="60">1 Hour</mat-option>
                <mat-option value="75">1.15 Hours</mat-option>
                <mat-option value="90">1.30 Hours</mat-option>
                <mat-option value="105">1.45 Hours</mat-option>
                <mat-option value="120">2 Hours</mat-option>
                <mat-option value="135">2.15 Hours</mat-option>
                <mat-option value="150">2.30 Hours</mat-option>
                <mat-option value="165">2.45 Hours</mat-option>
                <mat-option value="180">3 Hours</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          
          <div class="col-md-6">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label >Number of passengers</mat-label>
              <mat-select id="nopcenter" #passengers (selectionChange)="onPassengerchange(passengers.value)">
                <mat-option>None</mat-option>
                <mat-option value="1">1</mat-option>
                <mat-option value="2">2</mat-option>
                <mat-option value="3">3</mat-option>
                <mat-option value="4">4</mat-option>
                <mat-option value="5">5</mat-option>
                <mat-option value="6">6</mat-option>
                <mat-option value="7">7</mat-option>
                <mat-option value="8">8</mat-option>
                <mat-option value="9">9</mat-option>
                <mat-option value="10">10</mat-option>
                <mat-option value="11">11</mat-option>
                <mat-option value="12">12</mat-option>
                <mat-option value="13">13</mat-option>
                <mat-option value="14">14</mat-option>
                <mat-option value="15">15</mat-option>
                <!-- <mat-option value="16">16</mat-option>
                <mat-option value="17">17</mat-option>
                <mat-option value="18">18</mat-option>
                <mat-option value="19">19</mat-option>
                <mat-option value="20">20</mat-option> -->
              </mat-select>
            </mat-form-field>
          </div>
          
        </div>
        <div class="row" id="outstation-field">
          <div class="col-md-6">
            <mat-form-field appearance="outline" class="w-100" >
              <mat-label>Return Date</mat-label>
              <input matInput #returndate [matDatepicker]="picker2" [min]="minDate" (click)="picker2.open()">
              <mat-datepicker-toggle matSuffix [for]="picker2" ></mat-datepicker-toggle>
              <mat-datepicker #picker2></mat-datepicker>
            </mat-form-field>
          </div>
          <div class="col-md-6">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>Return Time</mat-label>
              <input matInput #returntime  id="returntime" type="time">
            </mat-form-field>
          </div>
          <div class="col-md-3"></div>
          <div class="col-md-6">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>Number of days of waiting</mat-label>
              <input matInput #nowdays type="number"  (change)="waitingdays(nowdays.value)">
            </mat-form-field>
          </div>
        </div>
        </div>
        <div class="bordered">
        <div class="row">
          <div class="col-12 text-center pl-0 pr-0">
              <label class="chooselabel mt-0">Choose Vehicle Type</label><br>
              <mat-radio-group [(ngModel)] ="vehicletype" (change)="vehiclechange()">
                  <mat-label id="sedan">
                    <mat-radio-button class="buttonradio" value="sedan">
                      <img width="100" src="assets/images/sedan.png">
                      <img width="100" src="assets/images/sedan-active.png" class="active">
                      <p>Sedan</p>
                    </mat-radio-button>
                    </mat-label>
                    <!-- <mat-label id="hatchback">
                      <mat-radio-button class="buttonradio" value="hatchback">
                        <img width="100" src="assets/images/hatchback.png">
                        <img width="100" src="assets/images/hatchback-active.png" class="active">
                        <p>Hatchback</p>
                      </mat-radio-button>
                    </mat-label> -->
                    <mat-label id="suv">
                      <mat-radio-button class="buttonradio" value="suv">
                        <img width="100" src="assets/images/suv.png">
                        <img width="100" src="assets/images/suv-active.png" class="active">
                        <p>SUV </p>
                      </mat-radio-button>
                    </mat-label>
                    <mat-label id="outstation">
                      <mat-radio-button class="buttonradio" value="Mini van">
                        <img width="100" src="assets/images/outstation.png">
                        <img width="100" src="assets/images/outstation-active.png" class="active">
                        <p>Mini Van </p>
                      </mat-radio-button>
                    </mat-label>
                    <mat-label id="passengervan">
                      <mat-radio-button class="buttonradio" value="passenger van">
                        <img width="100" src="assets/images/passenger-van.png">
                        <img width="100" src="assets/images/passenger-van-active.png" style="margin:0px auto;" class="active">
                        <p>Passenger Van</p>
                      </mat-radio-button>
                    </mat-label>
                    <mat-label id="truck">
                      <mat-radio-button class="buttonradio" value="truck">
                        <img width="100" src="assets/images/truck.png">
                        <img width="100" src="assets/images/truck-active.png" class="active">
                        <p>Truck</p>
                      </mat-radio-button>
                    </mat-label>
                </mat-radio-group>
          </div>
          <div class="col-md-3"></div>
          
          <div class="col-md-6 driverprefer pt-2" id="dpr1">
              <p *ngIf="checkfavdrvr==false" class="text-center">No favourite drivers</p>

            <mat-form-field appearance="outline" class="w-100">
              <mat-label *ngIf="checkfavdrvr==false">Preferred Driver</mat-label>
              <mat-label *ngIf="checkfavdrvr==true">Favourite Driver</mat-label>
              <mat-select name="driverprefer[]" #prefferddriver multiple>
                <div *ngIf="checkfavdrvr==true; else elseBlockz">
                  <div *ngFor="let e of slctli">
                      <div  *ngIf="e.favourite=='1'" >
                        <mat-option [value]="e.id+'/'+e.name" >{{ e.name }}</mat-option>
                      </div>
                  </div>
                </div>
                <ng-template #elseBlockz>
                  <mat-option *ngFor="let e of slctli" [value]="e.id+'/'+e.name" >{{ e.name }}</mat-option>
                </ng-template>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-md-3 driverprefer"></div>
          <div class="col-md-12">
            <hr>
          </div>
          <div class="col-md-2"></div>
          <div class="col-md-8  pt-2">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>Leave a comment</mat-label>
              <textarea matInput placeholder="" #comment></textarea>
            </mat-form-field>
          </div>
          </div>
          </div>
          <div class="bordered notregistered">
            <div class="row">
              <div class="col-12">
                <section class="example-section">
                  <mat-checkbox class="example-margin" id="checkbx" #check (change)="checkchanged()">Already have an account</mat-checkbox>
                </section>
              </div>
              <div class="col-12 p-0 instantreg">
                <div class="row w-100 text-center p-0 m-0 mt-3">
                  <div class="col-12 col-md-6">
                    <mat-form-field appearance="outline" class="w-100">
                      <mat-label>Full Name</mat-label>
                      <input matInput placeholder="" #name required>
                    </mat-form-field>
          
                  </div>
                  <div class="col-12 col-md-6">
                    <mat-form-field appearance="outline" class="w-100">
                      <mat-label>Mobile</mat-label>
                      <input matInput placeholder="" #mobile  required>
                    </mat-form-field>
          
                  </div>
                  <div class="col-12 col-md-6">
                    <mat-form-field appearance="outline" class="w-100">
                      <mat-label>Email</mat-label>
                      <input matInput placeholder="pat@example.com" #email [formControl]="email" required>
                      <mat-error *ngIf="email.invalid">{{getErrorMessage()}}</mat-error>
                    </mat-form-field>
          
                  </div>
                  <div class="col-12 col-md-6">
                    <mat-form-field appearance="outline" class="w-100">
                      <mat-label>Password</mat-label>
                      <input matInput [type]="hide ? 'password' : 'text'" #password>
                      <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'"
                        [attr.aria-pressed]="hide">
                        <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                      </button>
                    </mat-form-field>
                  </div>
               
                </div>
              </div>
              <div class="col-12 p-0 instantlogin">
                <div class="row w-100 text-center p-0 m-0 mt-3">
                <div class="col-12 col-md-6">
                  <mat-form-field appearance="outline" class="w-100">
                    <mat-label>Phone</mat-label>
                    <input matInput minlength="10" maxlength="10"  #loginphone required>
                  </mat-form-field>
        
                </div>
                <div class="col-12 col-md-6">
                  <mat-form-field appearance="outline" class="w-100">
                    <mat-label>Password</mat-label>
                    <input matInput [type]="hide ? 'password' : 'text'" #loginpassword>
                    <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'"
                      [attr.aria-pressed]="hide">
                      <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                    </button>
                  </mat-form-field>
                </div>
                </div>
              </div>
            </div>
          </div>
          <div class="bordered" id="otp">
            <div class="row">
                <div class="col-12 col-md-6">
                  <mat-form-field appearance="outline" class="w-100">
                    <mat-label>OTP</mat-label>
                    <input matInput placeholder="" #otp required>
                  </mat-form-field>
        
                </div>
                <div class="col-12 col-md-6">
                  <button mat-raised-button class="btn1 w-100 mt-0" (click)="generateOtp(mobile.value)">Resend OTP</button>
                </div>
            </div>
          </div>
          <div class="row ">
            <div class="col-12 notregistered">
              <p class="errormod" style="display:none;">
                All fields are required
              </p>
            </div>
            <div class="col-12 text-center driverprefer">
              <mat-checkbox class="example-margin" *ngIf="checkfavdrvr==false"  [(ngModel)]="checked2">Add preferred driver to favourite drivers</mat-checkbox>
            </div>
          <div class="col-12 notregistered">
            <button mat-raised-button class="btn1 w-100 instantreg" (click)="register(name.value,mobile.value,email.value,password.value,otp.value)">Submit</button>
            <button mat-raised-button class="btn1 w-100 instantlogin"  (click)="login(loginphone.value,loginpassword.value)">Submit</button>
          </div>
         
          <div class="col-12 text-center registeresproceed">
            <mat-checkbox class="example-margin" [(ngModel)]="checked">I have read and agree to the <a href="https://yodertoter.com/#/terms-conditions" target="_blank">terms and conditions</a></mat-checkbox>
          </div>
          <div class="col-3"></div>
          <div class="col-6 text-center registeresproceed">
              <button mat-raised-button class="btn1 w-100" (click)="bookingstep2(date.value,time.value,passengers.value,returndate.value,returntime.value,nowdays.value,prefferddriver.value,'now',comment.value);" [disabled]="isButtonClicked">Pay Now</button>
          </div>
          <!-- <div class="col-6 text-center registeresproceed">
            <button mat-raised-button class="btn1 w-100" (click)="bookingstep2(date.value,time.value,passengers.value,returndate.value,returntime.value,nowdays.value,prefferddriver.value,'later',comment.value);" [disabled]="isButtonClicked">Pay With Driver</button>
        </div> -->
          <div class="col-12">
            <div id="step2error">
              <p>abc</p>
            </div>
          </div>
        </div>
  
      </mat-card>
     </div>
     <div class="col-md-5">
      <div class="row">
        <div class="col-12 text-left">
          <h1 class="text-left">Ride <span>Summary</span></h1>
        </div>
      </div>
      <mat-card class="pricing" id="pricing">
              
        <div class="pricedetails">
          <h6 class="d-none" id="trigcalc" *ngIf="checkrun<5">
            <p *ngIf="checkrun<5">{{triggerCalculate}}</p>
          </h6>
          <h3>Total Distance : <span>{{totalMiles}} Miles</span></h3>
        <h3>Total Charge : <span>${{chargeRound}}</span></h3>
        <h3>Total Waiting Charge : <span>${{waitingchargeRound}}</span></h3>
        <div *ngIf="roundTripwaitingcharge!=undefined && ttype=='roundtrip'">
          <h3>Roound Trip Waiting Charge: <span>${{ roundTripwaitingcharge | number:'1.0-0' }}</span></h3>
        </div>
        </div>
        <div *ngIf="promocodeAmount>0">
          <h3>Promocode Discount : <span>-${{promocodeAmount}}</span></h3>
        </div>
        <h3 >Subtotal : <span class="subtotal">${{totalPrice}}</span></h3>
        <p *ngIf="totalPrice==15"><small>* Minimum Charge is $15</small></p>
        <!-- <button id="updatebtn">Update</button> -->
      </mat-card>
      <mat-card class="promocode_box mb-3" id="promocode_box">
        <p>Enter Your Promocode</p>
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>Promocode</mat-label>
          <input matInput class="w-100"  [(ngModel)]="promocode">
        </mat-form-field>
        <button mat-raised-button class="btn1 mt-0" (click)="checkPromocode()">Apply</button>
      </mat-card>
      
      <mat-card class="b-summary" id="bsummary">
        <div class="row">
          <div class="col-md-6">
            <button mat-fab class="edit-btn w-100 mb-3" aria-label="Example icon button with a bookmark icon" (click)="editbSummary()">
              Edit
              </button>
          </div>
          <div class="col-12">
            <mat-form-field appearance="outline" class="readonly-wrapper w-100">
              <mat-label>Trip Type</mat-label>
              <mat-select [(value)]="ttype" class="" #ttypet>
                <mat-option value="oneway">Oneway</mat-option>
                <mat-option value="roundtrip">Round Trip</mat-option>
                <mat-option value="outstation">Long Distance</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-12">
            <mat-form-field appearance="outline" class="readonly-wrapper w-100">
              <mat-label>Booking Type</mat-label>
              <mat-select [(value)]="btype" #btypet class="">
                <mat-option value="book">Book Now</mat-option>
                <mat-option value="schedule">Schedule</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-12">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>From</mat-label>
              <input matInput matGoogleMapsAutocomplete country="us" #from id="from">
            </mat-form-field>
          </div>
          <div class="col-12">
              <div *ngFor="let address of addresses; let i = index;" id="stopid{{i}}">
                  <div class="row">
                    <div class="col-6 pr-1">
                      
                      <mat-form-field appearance="outline" class=" readonly-wrapper w-100">
                          <mat-label>Stop {{i+1}}</mat-label>
                          <input matInput [(ngModel)]="address.address" value="{{addresses[i].stop_name}}" matGoogleMapsAutocomplete country="us" id="stopname{{i+1}}" name="address_{{address.id}}">
                        </mat-form-field>
                    </div>
                    <div class="col-4 pr-1">
                      <mat-form-field appearance="outline" class="readonly-wrapper" id="stopwait{{i+1}}">
                          <mat-label>Waiting Time</mat-label>
                          <select matNativeControl value="{{addresses[i].stop_waiting || 15}}">
                            <!-- <option value="{{addresses[i].stop_waiting || 15}}">
                              {{addresses[i].stop_waiting || 15}} Minutes
                            </option> -->
                            <option value="15">
                              15 Minutes
                            </option>
                            <option value="30">
                              30 Minutes
                            </option>
                            <option value="45">
                              45 Minutes
                            </option>
                            <option value="60">
                              1 Hour
                            </option>
                            <option value="75">
                              1.15 Hours
                            </option>
                            <option value="90">
                              1.30 Hours
                            </option>
                            <option value="105">
                              1.45 Hours
                            </option>
                            <option value="120">
                              2 Hours
                            </option>
                            <option value="135">
                              2.15 Hours
                            </option>
                              <option value="150">
                              2.30 Hours
                            </option>
                            <option value="165">
                              2.45 Hours
                            </option>
                            <option value="180">
                              3 Hours
                            </option>

                          </select>
                        </mat-form-field>
                    </div>
                    <div class="col-2 text-right pl-0">
                        <button mat-raised-button type="button" class="btn-remove readonly-wrapper" (click)="removeAddress(i)">
                          <mat-icon aria-hidden="false" aria-label="Example home icon">delete</mat-icon>
                        </button>
                    </div>
                  </div>
              </div>
              
              <label class="d-none">Enter waiting time if driver want to wait</label><br>
              <button mat-raised-button type="button" class="addbtn mb-2 text-center d-none" (click)="addAddress()">Add Stop <mat-icon aria-hidden="false" aria-label="Example home icon">add</mat-icon></button>
              
          </div>
          
          <div class="col-12">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>To</mat-label>
              <input matInput matGoogleMapsAutocomplete country="us" #to id="to">
            </mat-form-field>
          </div>
          <div class="col-12 pb-3" id="error5">
            <p>
              All fields are required.
            </p>
          </div>
          <div class="col-md-6">
            <div class="example-button-container">
              <button mat-button   class="booknow" (click)="preBook(from.value,to.value,ttypet.value,btypet.value);">
                Update
              </button>
            </div>
          </div>
        </div>
      </mat-card>

     </div>
   </div>
  </div>
</section>