export class GlobalConstants {
    public static apiURL: string = "https://yodertoter.com/yoderadmin/api/";
    // public static apiURL: string = "http://localhost/yoderadmin/api/";
    // public static apiURL: string = "https://akmaworld.com/yoderadmin/api/";

    public static googleKey: string = "AIzaSyAXYvaInis8uebWGsKPnI2weo4xvACSKHU";
    public static Stripepkey: string = "pk_live_51OtXM6B7K4XEReMH4O8msDWGCJQjRDAyTeLyWb0sf93PIWfeGbJh3EDkVBsOuX2r4tipi3P6l9KFuWQxN04vmjuY00KYk4GjWm";
    public static Stripeskey: string = "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";
    // public static Stripepkey: string = "pk_test_51Io3MtK4UJ2mEqgrzc4P2KwCoeeaVxx6VYKWFYzbKROHBe98OxbCKfhKmBEZmNYUpBfnAmZbDzxIxV395JBbguT100Ai4uNvlI";
    // public static Stripeskey: string = "sk_test_51Io3MtK4UJ2mEqgrPkbPZedyVCDHaujrpTAF2ExQKmHDoYLGS6aSPZiofPELDcpLwyKCpe2mcldAeeFNczwFkh8900LQrIRn4h";
    public static countrycode: string = "+1";
}
